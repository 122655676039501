import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2172.000000 1035.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1035.000000) scale(0.100000,-0.100000)">
         <path d="M2032 5073 l3 -2838 8733 -3 8732 -2 0 2840 0 2840 -8735 0 -8735 0
2 -2837z m7066 -51 l2 -1862 -2887 2 -2888 3 -3 1850 c-1 1018 0 1856 3 1863
3 10 586 12 2887 10 l2883 -3 3 -1863z m5452 708 l0 -541 -112 3 -113 3 -5 38
-5 39 -24 -32 c-32 -44 -101 -74 -155 -66 -114 17 -181 130 -193 327 -10 170
27 301 106 372 69 62 164 59 234 -9 l37 -36 0 214 c0 118 3 218 7 221 3 4 55
7 115 7 l108 0 0 -540z m-2373 465 c94 -43 142 -100 188 -225 25 -67 28 -91
32 -230 7 -239 -32 -376 -135 -479 -88 -88 -227 -113 -367 -65 -107 37 -195
163 -226 325 -18 95 -16 310 5 389 44 172 133 277 263 310 65 16 176 5 240
-25z m4876 9 l47 -16 0 -119 c0 -65 -1 -119 -2 -119 -2 0 -30 11 -63 25 -104
43 -185 27 -185 -36 0 -43 23 -72 110 -142 92 -75 156 -146 184 -207 27 -59
28 -166 3 -232 -22 -56 -98 -131 -165 -161 -64 -28 -204 -29 -277 -1 l-50 20
-3 122 -3 123 53 -27 c33 -17 75 -28 113 -31 51 -4 63 -2 83 16 14 14 22 34
22 55 0 28 -10 42 -57 85 -208 189 -242 237 -251 351 -10 149 69 268 206 307
57 16 168 10 235 -13z m-6120 -99 c10 -55 30 -172 46 -260 16 -88 31 -153 34
-145 2 8 12 60 22 115 18 103 61 333 70 373 5 22 8 22 155 22 83 0 150 -1 150
-3 0 -2 11 -102 25 -222 60 -511 85 -741 85 -767 l0 -28 -114 0 -113 0 -13
143 c-33 364 -48 522 -49 517 -1 -3 -32 -151 -69 -330 l-67 -325 -74 -3 c-41
-2 -78 1 -82 5 -4 4 -38 153 -75 330 -38 177 -70 320 -72 318 -2 -1 -14 -131
-28 -286 -13 -156 -26 -303 -30 -326 l-5 -43 -110 0 c-60 0 -109 3 -109 7 0
16 100 941 106 976 l6 38 146 -3 147 -3 18 -100z m2057 81 c118 -44 187 -135
197 -259 8 -99 -16 -170 -81 -232 l-52 -50 88 -217 c48 -120 88 -222 88 -228
0 -7 -41 -10 -122 -8 l-123 3 -83 205 c-46 113 -87 214 -92 225 -5 12 -9 -68
-9 -207 l-1 -228 -115 0 -115 0 0 511 0 511 188 -4 c141 -4 198 -9 232 -22z
m840 -91 l0 -115 -120 0 -120 0 0 -85 0 -84 103 -3 102 -3 0 -110 0 -110 -102
-3 -103 -3 0 -79 0 -80 120 0 120 0 0 -115 0 -115 -235 0 -235 0 0 510 0 510
235 0 235 0 0 -115z m3730 -245 c0 -387 3 -415 51 -440 33 -18 99 -6 121 22
16 21 18 57 20 398 l3 375 120 0 120 0 0 -380 c-1 -362 -2 -383 -22 -450 -25
-79 -68 -137 -121 -164 -130 -66 -361 -44 -441 40 -78 82 -90 167 -91 607 l0
352 120 0 120 0 0 -360z m983 350 c175 -26 270 -122 270 -275 1 -83 -21 -134
-80 -185 -35 -31 -36 -34 -19 -44 89 -52 121 -117 114 -235 -4 -67 -9 -87 -34
-123 -15 -24 -27 -47 -26 -51 1 -5 -2 -6 -6 -3 -5 3 -18 -4 -28 -15 -55 -61
-154 -81 -374 -77 l-165 3 -3 495 c-1 272 0 501 3 508 6 15 246 17 348 2z
m-2843 -204 l0 -95 43 -3 42 -3 0 -90 0 -90 -42 -3 -43 -3 0 -260 0 -259 -115
0 -115 0 0 260 0 260 -40 0 -40 0 0 95 0 95 40 0 40 0 0 95 0 95 115 0 115 0
0 -94z m-759 -83 c19 -4 50 -24 76 -49 l43 -43 0 40 0 40 113 -3 112 -3 0
-350 0 -350 -112 -3 -113 -3 0 43 0 42 -37 -38 c-119 -122 -268 -65 -323 123
-51 179 -23 406 63 500 25 27 92 60 123 61 12 0 36 -3 55 -7z m1194 -7 c19 -8
48 -30 64 -48 20 -22 31 -28 32 -18 1 8 2 24 3 35 1 19 9 20 114 23 l112 3 0
-356 0 -355 -110 0 -110 0 0 47 0 48 -22 -31 c-47 -66 -120 -94 -192 -74 -51
14 -76 34 -109 87 -45 70 -61 145 -62 278 0 97 4 134 22 191 46 145 151 214
258 170z m2483 -1028 c21 -21 14 -58 -14 -68 -35 -14 -8083 -14 -8118 0 -28
10 -35 47 -14 68 17 17 8129 17 8146 0z m-5028 -588 l0 -430 -90 0 -90 0 0
430 0 430 90 0 90 0 0 -430z m-752 293 l-3 -98 -62 -3 -63 -3 0 -309 0 -310
-100 0 -100 0 0 310 0 310 -60 0 -60 0 0 100 0 100 225 0 226 0 -3 -97z m294
-143 c21 -6 51 -22 68 -36 16 -14 30 -22 30 -17 0 10 22 -28 45 -76 15 -33 34
-138 35 -188 0 -23 -1 -23 -145 -23 l-145 0 0 -31 c0 -82 72 -122 108 -59 16
29 19 30 91 30 l74 0 -7 -32 c-8 -43 -53 -107 -95 -135 -77 -52 -192 -38 -259
30 -49 50 -69 94 -81 182 -33 232 103 404 281 355z m847 0 c94 -27 153 -105
171 -227 18 -125 32 -113 -135 -113 l-145 0 0 -34 c0 -50 37 -91 74 -82 15 3
32 18 38 31 10 23 16 25 85 25 83 0 84 -2 54 -72 -32 -75 -111 -128 -191 -128
-36 0 -103 20 -121 36 -9 8 -19 11 -24 7 -4 -5 -5 -3 -2 3 4 7 1 14 -7 17 -24
9 -65 109 -75 180 -33 235 102 408 278 357z m544 -13 l37 -16 0 -81 c0 -77 -1
-80 -19 -70 -36 19 -72 11 -102 -20 -26 -26 -29 -37 -29 -94 0 -55 4 -69 26
-95 27 -33 67 -41 105 -21 18 10 19 7 19 -70 l0 -81 -37 -15 c-122 -52 -224
-5 -274 125 -26 67 -32 221 -10 286 48 147 158 205 284 152z m464 -6 c84 -43
124 -120 131 -257 5 -108 -13 -181 -60 -238 -47 -58 -100 -80 -189 -80 -66 -1
-81 3 -118 27 -92 61 -127 149 -119 301 8 138 60 227 154 262 50 19 150 11
201 -15z m618 15 c19 -8 41 -28 50 -46 l17 -32 31 30 c64 62 141 77 198 39 53
-35 58 -64 58 -329 l1 -238 -90 0 -90 0 0 180 c0 194 -7 230 -46 230 -50 0
-54 -16 -54 -221 l0 -189 -90 0 -90 0 0 183 c0 194 -7 227 -50 227 -43 0 -50
-33 -50 -227 l0 -183 -90 0 -90 0 0 285 0 285 90 0 90 0 0 -32 0 -32 28 27
c31 30 86 56 120 57 12 0 38 -6 57 -14z m-2315 -881 c0 -16 -38 -40 -48 -30
-4 3 4 15 18 25 30 24 30 24 30 5z m-182 -45 c-4 -28 -3 -30 31 -32 l36 -1
-36 -4 c-36 -4 -36 -4 -52 -76 -9 -39 -13 -75 -11 -79 3 -4 16 -3 30 2 36 14
39 13 19 -10 -13 -14 -29 -20 -49 -18 -27 3 -31 8 -34 35 -2 17 3 56 11 87 15
55 15 56 -6 57 -21 2 -21 2 1 6 12 3 22 12 22 22 0 18 18 41 33 41 5 0 7 -13
5 -30z m1664 5 c3 -13 0 -26 -6 -29 -6 -2 6 -5 27 -5 21 -1 36 -5 33 -9 -3 -5
-18 -7 -34 -4 -29 4 -30 3 -43 -65 -7 -37 -14 -72 -14 -77 0 -17 22 -17 39 -1
14 14 16 15 16 1 0 -19 -30 -36 -64 -36 -23 0 -26 4 -26 33 0 17 6 58 14 90
13 56 13 57 -8 58 l-21 2 21 4 c14 3 24 14 28 34 7 35 32 38 38 4z m-2852 -49
c0 -8 -3 -22 -6 -31 -6 -14 -8 -13 -18 7 -16 32 -40 33 -69 4 -30 -30 -43 -87
-25 -116 13 -22 44 -23 91 -5 9 4 17 3 17 -2 0 -13 -60 -33 -98 -33 -72 0 -69
111 4 169 32 25 104 30 104 7z m181 -7 c34 -64 -32 -169 -106 -169 -75 0 -74
110 1 169 36 28 90 28 105 0z m129 -14 l0 -36 31 36 c23 25 39 35 61 35 l30 0
-5 -67 c-3 -38 -6 -72 -7 -78 -5 -24 2 -26 28 -9 26 17 27 17 12 -2 -22 -28
-32 -34 -59 -34 -23 0 -24 2 -18 58 4 32 9 66 13 75 4 13 1 17 -15 17 -27 0
-90 -85 -91 -122 0 -21 -5 -28 -20 -28 -23 0 -24 4 -5 90 7 35 11 68 8 73 -3
5 -16 -2 -29 -14 -30 -28 -32 -14 -2 18 12 13 32 23 45 23 20 0 23 -5 23 -35z
m240 3 l0 -32 33 32 c21 20 43 32 60 32 25 0 27 -3 27 -37 -1 -21 -5 -57 -9
-80 -10 -52 -5 -58 28 -36 25 16 26 16 11 -3 -22 -28 -32 -34 -59 -34 -23 0
-24 2 -18 58 4 32 9 66 13 75 4 13 1 17 -15 17 -27 0 -90 -85 -91 -122 0 -21
-5 -28 -20 -28 -23 0 -24 4 -5 90 7 35 11 68 8 73 -3 5 -16 -2 -29 -14 -30
-28 -32 -14 -2 18 12 13 32 23 45 23 19 0 23 -5 23 -32z m318 0 c4 -34 -30
-67 -80 -74 -41 -7 -48 -23 -22 -49 16 -16 27 -16 89 2 19 5 19 4 4 -12 -10
-9 -38 -20 -65 -23 -38 -4 -50 -1 -61 13 -43 60 27 180 101 173 25 -2 32 -8
34 -30z m202 15 c0 -10 -5 -25 -10 -33 -9 -14 -11 -13 -14 11 0 7 -8 17 -17
22 -44 28 -105 -74 -80 -131 10 -21 16 -23 47 -17 19 3 42 9 50 12 8 3 14 1
14 -4 0 -13 -60 -33 -98 -33 -72 0 -69 111 4 169 35 27 104 29 104 4z m275
-35 c-19 -79 -25 -118 -18 -118 4 0 17 10 30 22 18 17 23 18 23 6 0 -20 -42
-48 -71 -48 -20 0 -21 3 -14 79 l7 80 -24 -6 c-32 -8 -14 9 25 24 55 20 56 18
42 -39z m125 17 l0 -36 31 36 c22 25 39 35 60 35 27 0 29 -3 29 -37 -1 -21 -5
-58 -10 -83 -4 -25 -7 -47 -4 -49 10 -11 52 32 58 59 3 18 23 50 45 71 35 35
44 39 90 39 28 0 51 -3 51 -7 0 -5 -9 -53 -20 -107 -30 -146 -62 -186 -148
-186 -64 0 -84 46 -38 89 15 14 22 29 19 42 -6 20 -6 20 -27 0 -12 -12 -32
-21 -44 -21 -18 0 -22 6 -22 33 0 17 5 49 11 71 30 110 -69 28 -105 -86 -3
-10 -13 -18 -22 -18 -13 0 -15 6 -10 33 16 75 23 128 16 132 -4 2 -17 -5 -28
-16 -29 -26 -28 -12 0 18 12 13 32 23 45 23 20 0 23 -5 23 -35z m565 25 c3 -5
9 -32 12 -59 8 -70 18 -82 43 -51 11 14 28 46 37 73 14 37 22 47 40 47 20 0
21 -3 13 -22 -5 -13 -19 -61 -31 -108 -32 -132 -65 -170 -146 -170 -30 0 -34
2 -23 15 7 9 22 12 39 9 35 -7 57 14 71 69 6 23 14 51 17 62 4 14 -3 10 -22
-13 -45 -53 -72 -32 -74 61 -1 20 -5 37 -11 37 -5 0 -7 8 -3 18 6 15 4 15 -15
-2 -15 -13 -22 -15 -22 -6 0 34 59 66 75 40z m325 -9 c37 -69 -28 -171 -109
-171 -36 0 -51 22 -51 76 0 52 62 114 113 114 26 0 39 -6 47 -19z m125 -26 c0
-22 -3 -55 -7 -72 -6 -24 -4 -33 6 -33 27 0 86 88 86 130 0 13 7 20 20 20 22
0 22 8 1 -112 -5 -26 -6 -50 -3 -53 3 -3 16 2 30 11 18 12 23 13 20 2 -7 -20
-52 -42 -71 -35 -11 4 -17 18 -17 39 l0 32 -16 -24 c-24 -36 -44 -50 -71 -50
-20 0 -23 5 -23 38 0 20 5 55 10 76 11 50 4 61 -26 41 -13 -8 -24 -11 -24 -6
0 16 43 42 65 39 16 -2 20 -11 20 -43z m595 26 c37 -69 -28 -171 -109 -171
-71 0 -68 112 5 169 35 27 90 28 104 2z m240 -15 l0 -34 34 34 c21 21 44 34
60 34 43 0 27 -32 -18 -35 -28 -3 -75 -62 -89 -112 -8 -30 -16 -43 -29 -43
-21 0 -22 5 -3 90 7 35 11 68 9 72 -3 4 -16 1 -30 -8 -30 -20 -31 -12 -2 16
12 11 32 20 45 20 20 0 23 -5 23 -34z m268 2 c4 -34 -30 -67 -80 -74 -41 -7
-48 -23 -22 -49 16 -16 27 -16 89 2 19 5 19 4 4 -12 -10 -9 -38 -20 -65 -23
-38 -4 -50 -1 -61 13 -43 60 27 180 101 173 25 -2 32 -8 34 -30z m162 14 c0
-30 -12 -33 -32 -10 -17 20 -20 20 -35 6 -15 -15 -13 -20 16 -56 17 -22 31
-49 31 -60 0 -29 -37 -52 -82 -52 -40 0 -51 9 -39 28 6 10 13 11 35 1 22 -10
29 -10 42 3 14 14 13 19 -15 51 -37 41 -39 70 -9 91 32 23 88 21 88 -2z m191
-3 c34 -64 -32 -169 -106 -169 -75 0 -74 110 1 169 36 28 90 28 105 0z m116
-37 c-4 -32 -9 -67 -13 -76 -5 -14 -3 -17 12 -14 24 4 84 93 84 124 0 17 6 24
20 24 23 0 24 -4 5 -90 -7 -35 -11 -68 -8 -73 3 -4 16 -1 29 8 29 18 32 5 5
-19 -11 -10 -31 -16 -48 -14 -25 3 -28 6 -25 38 l2 35 -15 -27 c-17 -31 -44
-48 -74 -48 -18 0 -21 6 -21 38 1 20 5 57 10 82 4 25 7 47 4 49 -2 2 -15 -4
-29 -13 -14 -9 -25 -12 -25 -7 0 16 42 41 69 41 23 0 24 -2 18 -58z m243 21
l0 -37 17 24 c10 14 17 28 16 31 -1 3 3 6 10 7 7 1 27 5 46 8 31 6 32 5 22
-14 -7 -13 -17 -19 -26 -15 -23 8 -84 -62 -97 -113 -9 -31 -17 -44 -30 -44
-21 0 -22 5 -3 90 7 35 11 68 9 72 -3 4 -17 0 -32 -9 l-27 -17 24 27 c14 15
35 27 48 27 20 0 23 -5 23 -37z m280 23 c0 -8 -3 -22 -6 -31 -6 -14 -8 -13
-18 7 -6 12 -19 24 -29 26 -19 4 -71 -44 -62 -57 3 -5 1 -12 -5 -16 -14 -9
-12 -47 5 -69 14 -18 25 -18 83 -1 35 11 26 -10 -11 -23 -85 -31 -124 -6 -114
72 8 58 61 106 117 106 28 0 40 -4 40 -14z m176 -1 c17 -44 -32 -95 -91 -95
-30 0 -33 -20 -6 -44 12 -11 28 -16 40 -12 12 3 30 9 41 12 33 9 23 -13 -11
-25 -51 -17 -97 -14 -109 8 -23 45 -3 111 46 150 31 25 82 28 90 6z m174 -4
c0 -11 -4 -23 -10 -26 -5 -3 -10 0 -10 8 0 21 -31 30 -47 14 -12 -11 -9 -19
17 -52 37 -46 38 -77 5 -99 -25 -16 -105 -23 -105 -8 0 21 23 30 47 19 19 -8
27 -7 39 5 14 14 13 19 -15 51 -17 19 -31 43 -31 53 0 31 30 54 72 54 31 0 38
-4 38 -19z m-5340 -127 c11 -12 10 -18 -3 -32 -16 -15 -18 -15 -34 0 -13 14
-14 20 -3 32 7 9 16 16 20 16 4 0 13 -7 20 -16z m100 0 c11 -12 10 -18 -3 -31
-13 -13 -19 -14 -31 -3 -19 15 -21 36 -3 43 20 9 24 8 37 -9z m100 0 c11 -12
10 -18 -3 -32 -16 -15 -18 -15 -34 0 -13 14 -14 20 -3 32 7 9 16 16 20 16 4 0
13 -7 20 -16z"/>
<path d="M14189 5673 c-19 -30 -23 -51 -23 -127 -1 -126 32 -179 99 -162 34 9
55 71 55 166 0 114 -23 160 -80 160 -22 0 -34 -8 -51 -37z"/>
<path d="M11961 5958 c-20 -22 -35 -52 -43 -88 -16 -71 -15 -302 1 -359 34
-119 150 -138 203 -32 21 42 23 59 23 211 0 136 -3 173 -18 212 -35 91 -110
116 -166 56z"/>
<path d="M12800 5895 l0 -115 25 0 c38 0 90 25 109 51 35 50 9 141 -48 164
-20 8 -47 15 -61 15 l-25 0 0 -115z"/>
<path d="M18430 5906 l0 -106 25 0 c82 0 138 66 117 137 -13 45 -36 61 -97 70
l-45 6 0 -107z"/>
<path d="M18430 5494 l0 -107 51 6 c61 7 96 28 110 65 28 70 -32 142 -117 142
l-44 0 0 -106z"/>
<path d="M14931 5689 c-27 -29 -34 -70 -30 -168 3 -61 9 -86 25 -108 29 -39
79 -39 108 0 18 25 21 42 21 132 0 90 -3 107 -21 132 -28 37 -74 43 -103 12z"/>
<path d="M16101 5679 c-25 -41 -35 -130 -21 -198 14 -75 35 -101 82 -101 20 0
32 9 49 37 20 32 24 51 24 128 0 115 -21 158 -78 163 -32 3 -39 -1 -56 -29z"/>
<path d="M13036 4288 c-9 -12 -16 -35 -16 -50 0 -28 1 -28 61 -28 59 0 61 1
55 23 -3 12 -6 27 -6 34 0 20 -30 43 -55 43 -13 0 -31 -10 -39 -22z"/>
<path d="M13886 4288 c-9 -12 -16 -35 -16 -50 0 -28 1 -28 61 -28 59 0 61 1
55 23 -3 12 -6 27 -6 34 0 20 -30 43 -55 43 -13 0 -31 -10 -39 -22z"/>
<path d="M14834 4259 c-28 -31 -32 -160 -7 -209 15 -28 24 -35 48 -35 24 0 34
7 48 35 25 47 19 180 -8 210 -23 26 -57 26 -81 -1z"/>
<path d="M12006 3445 c-51 -55 -39 -162 17 -139 43 18 71 133 37 154 -22 14
-29 12 -54 -15z"/>
<path d="M12725 3450 c-23 -9 -39 -26 -48 -53 -6 -15 -2 -16 21 -11 32 8 62
32 62 50 0 18 -12 23 -35 14z"/>
<path d="M13640 3447 c-28 -14 -50 -54 -53 -95 -2 -23 1 -27 23 -24 14 1 37
17 52 35 30 34 53 87 38 87 -4 0 -14 2 -22 5 -7 3 -24 -1 -38 -8z"/>
<path d="M13650 3325 c-18 -19 -42 -35 -53 -37 -11 -2 -29 -15 -41 -31 -17
-24 -18 -29 -6 -41 29 -29 67 -30 93 -3 22 22 56 131 45 143 -3 2 -19 -12 -38
-31z"/>
<path d="M14193 3455 c-21 -15 -43 -69 -43 -107 0 -18 25 -48 40 -48 19 0 58
50 64 83 7 39 -7 87 -27 87 -7 0 -22 -7 -34 -15z"/>
<path d="M14912 3453 c-20 -18 -42 -72 -42 -104 0 -18 25 -49 40 -49 19 0 58
50 64 83 8 40 -7 87 -28 87 -7 0 -23 -8 -34 -17z"/>
<path d="M15445 3450 c-23 -9 -39 -26 -48 -53 -6 -15 -2 -16 21 -11 32 8 62
32 62 50 0 18 -12 23 -35 14z"/>
<path d="M15766 3445 c-51 -55 -39 -162 17 -139 43 18 71 133 37 154 -22 14
-29 12 -54 -15z"/>
<path d="M16595 3435 c-33 -32 -33 -54 -1 -48 32 6 60 38 53 58 -9 21 -24 19
-52 -10z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
